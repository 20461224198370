<template>
  <div class="smartLink">
    <!-- <div class="main-Title bgff">
      <h2>Smart Link</h2>
    </div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form v-model="smartLinks" size="mini">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
              <el-form-item label="SmartLinkId" label-width="90px" class="mb0">
                <el-input
                  v-model="smartLinks.smartLinkId"
                  placeholder="smartLinkId here..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
              <el-form-item label="Status" label-width="60px" class="mb0">
                <el-select v-model="smartLinks.status" filterable clearable class="w100">
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
              <el-form-item class="mb0" label-width="0px">
                <el-button type="primary" @click="getDataList(1)" :loading="loading.list"
                  >In Browser</el-button
                >
                <el-button type="primary" @click="addDataListClick">Add</el-button>
                <el-button type="primary" @click="delDataListClick">Delete</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          class="w100"
          stripe
          border
          :data="dataList"
          highlight-current-row
          size="mini"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-table
                @selection-change="handleDataTagsSelectionChange"
                :data="scope.row.offers"
                ref="table"
                border
                stripe
                size="mini"
              >
                <el-table-column type="selection" align="center" width="55"></el-table-column>
                <el-table-column label="OfferId" prop="offerId" align="center" min-width="100">
                  <template slot-scope="scope">
                    <span @click="detailClick(scope.row)" class="cor337ab7">{{
                      scope.row.offerId
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="OfferName"
                  prop="offerName"
                  align="center"
                  min-width="100"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column label="Status" prop="status" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.status }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="ClickCapDaily"
                  prop="clickCapDaily"
                  align="center"
                  min-width="120"
                ></el-table-column>
                <el-table-column label="Priority" prop="priority" align="center"></el-table-column>
                <el-table-column label="SourceId" prop="sourceId" min-width="100">
                  <template slot-scope="scope">
                    <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                      scope.row.sourceId
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Countries"
                  prop="countries"
                  align="center"
                  min-width="100"
                  show-overflow-tooltip
                ></el-table-column>
                <!-- <el-table-column label="Payout" prop="payout"></el-table-column> -->
                <!-- <el-table-column label="Carriers" prop="carriers" min-width="100"></el-table-column> -->
                <el-table-column
                  label="Platforms"
                  prop="platforms"
                  min-width="100"
                ></el-table-column>
                <!-- <el-table-column
                  label="CreateTime"
                  prop="createTime"
                  align="center"
                  min-width="100"
                ></el-table-column> -->
                <el-table-column fixed="right" label="Option" min-width="120" align="center">
                  <template slot-scope="scope">
                    <el-button type="primary" @click="updateSmartLinksClick(scope.row)"
                      >Update</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="SmartLinkId" prop="smartLinkId" align="center" min-width="110">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="smdetailClick(scope.row)">{{
                scope.row.smartLinkId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Status"
            prop="status"
            align="center"
            min-width="90"
          ></el-table-column>
          <el-table-column
            label="Name"
            prop="name"
            align="center"
            min-width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="SourceId" prop="sourceId" align="center" min-width="100">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Countries"
            prop="countries"
            min-width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Platforms"
            prop="platforms"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column
            label="Revenue"
            prop="revenue"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column label="Payout" prop="payoutValue" align="center"></el-table-column>
          <el-table-column
            label="Operator"
            prop="operator"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Operate Time"
            prop="operateTime"
            min-width="110"
          ></el-table-column>
        </el-table>
        <div class="align-c">
          <pagination
            class="block pagePanel"
            :pageTotal="total"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :pageSize="pageParam.pageSize"
            :currentPage="pageParam.page"
          ></pagination>
        </div>
      </el-card>
      <!-- 添加模态框 -->
      <el-dialog
        :visible.sync="addSmartLinkVisible"
        :close-on-click-modal="false"
        :append-to-body="true"
        title="Add SmartLink"
      >
        <el-form :model="addSmartLinks" label-width="100px">
          <el-row v-if="isUpdate !== true">
            <el-col :lg="12" :md="24">
              <el-form-item label="SmartLinkId:">
                <el-input
                  v-model="addSmartLinks.smartLinkId"
                  placeholder="smartLinkId here..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24">
              <el-form-item label="OfferId:">
                <el-input v-model="addSmartLinks.offerId" placeholder="offerId here..."></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :lg="12" :md="24">
              <el-form-item label="ClickCap:">
                <el-input
                  v-model="addSmartLinks.clickCap"
                  placeholder="clickCap here..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24">
              <el-form-item label="Priority:">
                <el-input
                  v-model="addSmartLinks.priority"
                  placeholder="priority here..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" align="right">
              <el-form-item label-width="0px" class="mb0">
                <el-button @click="addSmartLinkVisible = false">取 消</el-button>
                <el-button type="primary" @click="addOrUpdateApi">确 定</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import OfferSmartLinkCon from '../controllers/product/OfferSmartLink';
  export default {
    name: 'OfferSmartLink',
    ...OfferSmartLinkCon,
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
</style>
