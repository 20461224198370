import {
  getSmartLinkList,
  insertSmartLink,
  deleteSmartLink,
  updateSmartLink,
} from 'api/product/smartLink';
import Pagination from '@/components/pagination';
import { myMixin } from '@/mixins/mixins.js';

let clickTimer = null;
export default {
  mixins: [myMixin],
  components: {
    Pagination,
  },
  data() {
    return {
      smartLinks: {
        status: 'active',
        smartLinkId: null,
      },
      loading: {
        list: false,
      },
      addSmartLinkVisible: false,
      dataList: [],
      total: null,
      pageParam: {
        page: 1,
        pageSize: 10,
      },
      statusOptions: [
        {
          label: 'ACTIVE',
          value: 'active',
        },
        {
          label: 'PENDING',
          value: 'pending',
        },
        {
          label: 'PAUSED',
          value: 'paused',
        },
        {
          label: 'HOLD',
          value: 'hold',
        },
        {
          label: 'BLOCK',
          value: 'block',
        },
        {
          label: 'ALL',
          value: 'ALL',
        },
      ],
      addSmartLinks: {
        smartLinkId: null,
        offerId: null,
        clickCap: null,
        priority: null,
      },
      updateId: null,
      isUpdate: false,
      dataSelectionList: [],
      delArr: [],
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList(curPage) {
      if (curPage) {
        this.pageParam.page = curPage;
      }
      let param = {
        ...this.smartLinks,
        page: this.pageParam.page,
        pageSize: this.pageParam.pageSize,
      };
      this.loading.list = true;
      getSmartLinkList(param)
        .then((response) => {
          if (response.code == 200) {
            this.dataList = response.result;
            this.total = response.total;
          } else {
            this.$message.error(response.status.msg);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e.message);
        });
    },
    addDataListClick() {
      this.isUpdate = false;
      this.setNull(this.addSmartLinks);
      this.addSmartLinkVisible = true;
    },
    handleDataTagsSelectionChange(val) {
      this.dataSelectionList = val;
    },
    delDataListClick() {
      const length = this.dataSelectionList.length;
      this.$confirm('Are you want to delete ?', 'Tip', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then(() => {
        for (let i = 0; i < length; i++) {
          this.delArr.push(this.dataSelectionList[i].id);
        }

        deleteSmartLink(this.delArr.join()).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getDataList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      });
      this.delArr = [];
    },
    updateSmartLinksClick(row) {
      this.isUpdate = true;
      this.updateId = row.id;
      this.addSmartLinkVisible = true;
    },
    handleSizeChange(size) {
      this.pageParam.pageSize = size;
      this.handleCurrentChange(1);
    },
    async handleCurrentChange(currentPage) {
      this.pageParam.page = currentPage;
      this.offerListApi();
    },
    addOrUpdateApi() {
      let param;
      if (this.isUpdate) {
        param = {
          id: this.updateId,
          clickCap: this.addSmartLinks.clickCap,
          priority: this.addSmartLinks.priority,
        };
        updateSmartLink(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getDataList();
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      } else {
        insertSmartLink({ ...this.addSmartLinks }).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getDataList();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      }
      this.addSmartLinkVisible = false;
      this.isUpdate = false;
    },
    detailClick(row) {
      if (clickTimer) {
        window.clearTimeout(clickTimer);
        clickTimer = null;
      }
      var that = this;
      clickTimer = window.setTimeout(function() {
        that.$nextTick(() => {
          const { href } = that.$router.resolve({
            path: '/offer/detail',
            query: {
              offerId: row.offerId,
            },
          });
          window.open(href, '_blank');
        });
      }, 300);
    },
    smdetailClick(row) {
      if (clickTimer) {
        window.clearTimeout(clickTimer);
        clickTimer = null;
      }
      var that = this;
      clickTimer = window.setTimeout(function() {
        that.$nextTick(() => {
          const { href } = that.$router.resolve({
            path: '/offer/detail',
            query: {
              offerId: row.smartLinkId,
            },
          });
          window.open(href, '_blank');
        });
      }, 300);
    },
    sourcedetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/source/detail',
        query: {
          sourceId: row.sourceId,
        },
      });
      window.open(href, '_blank');
    },
  },
};
